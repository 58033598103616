import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import BlankScreen from './components/layout/BlankScreen/BlankScreen';
// import * as serviceWorker from './serviceWorker';

const OptimizedApp = lazy(() => import('./OptimizedApp/OptimizedApp'))

ReactDOM.render(
  <Suspense fallback={<BlankScreen />}>
    <OptimizedApp />
  </Suspense>
  , document.getElementById('root')
);




// import { createStore, applyMiddleware, compose } from 'redux'; //Creates a redux store that holds the complete state tree of your app. Compose is used to combine multiple "store enhancers" together (kind of like connect).  This is necessary to allow Firebase and Firestore connections to this app.
// import rootReducer from './store/reducers/rootReducer'; //The root reducer is the combination of all reducers of the app.
// import { Provider } from 'react-redux' //Binding Layer. Surrounds the app and gives the application access to the Redux Store.
// import thunk from 'redux-thunk' //redux-thunk is Middleware that is used to allow for asyncronous interactions / actions to the database.

// //Needed to make slight adjustment to connect to Firebase and Firestore. Added "createFirestoreInstance"
// import { reduxFirestore, getFirestore, createFirestoreInstance } from 'redux-firestore' //Allows redux to connect to firestore. reduxFirestore() is a store enhancer.

// //Needed to make slight adjustment to connect to Firebase and Firestore. Added "ReactReduxFirebaseProvider"
// import { getFirebase } from 'react-redux-firebase' //Allows redux to connect to firebase database. reactReduxFirebase() is a store enhancer.
// import {  ReactReduxFirebaseProvider } from 'react-redux-firebase' 
// import firebaseConfig from './config/firebaseConfiguration'

// //Needed to include this to allow the website to connect to firebase.
// import firebase from 'firebase/app';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import './index.css';
// import OptimizedApp from './OptimizedApp';

// // //for render on auth ready
// // import { useSelector  } from 'react-redux'
// // import { isLoaded  } from 'react-redux-firebase';

// // //Page Transition imports
// // import { PageTransition } from './components/layout/PageTransition';
// // import {motion} from 'framer-motion';

// // import AuthIsLoaded from './AuthIsLoaded'
// // import App from './App'
// // import {ReactReduxFirebaseProvider} from ('react-redux-firebase')
// const App = lazy(()=>import('./App'));
// const AuthIsLoaded = lazy(()=>import('./AuthIsLoaded'));
// // const /reduxfirebase  = lazy(()=>import('react-redux-firebase'))



// //Creates the Redux store and stores it in reduxStore. Passes in the root reducer to the store.
// //Apply/enhances the reduxStore with Middleware thunk to allow for asyncronous tasks in the reduxStore.
// //Add "withExtraArgument" onto the 'thunk' to allow connection of firebase/firestore functions.
// const reduxStore = createStore(
//   rootReducer, 
//   compose(//Add compose here to combine multiple Store Enhancers like "thunk", "getFirebase" and "getFirestore"
//     applyMiddleware(thunk.withExtraArgument({getFirebase, getFirestore} ) ),
//     reduxFirestore(firebase, firebaseConfig), //This is a "store enhancer" functions that take in the configuration info of Firebase/Firestore and allow the store to connect to it.
//     )
// );


// //This content is added beyond the tutorial.  This is necessary to connect
// //to the firebase database.
// //The multiple calls of "config" is a problem.  Need to find a better way of linking up Firebase Profile info.
// const rrfProps = {
//   firebase,
//   config: firebaseConfig,

//   dispatch: reduxStore.dispatch,
//   createFirestoreInstance
// };

// function AuthIsLoaded({ children }) {
//   const auth = useSelector(state => state.firebase.auth)
//   if (!isLoaded(auth)) return (
//     <motion.div exit='exit' variants={PageTransition} initial='hidden' animate='show' className="center"> 
//         <p color='white'></p>
//     </motion.div>
//   );
//       return children
// }

//Imports used to update the statistics of the users every 24 hours.
/*
const cron = require("node-cron");
let shell = require("shelljs");

cron.schedule("* * * * * *", function(){
  console.log("Scheduler running...");
  if(shell.exec("dir").code !==0){
    console.log("Somethign went wrong");
  }
}
*/

//This is where we render the application to the virtual dom (i.e. users visible page).  We need to wait
//until firebase authorization is initialized BEFORE we render, otherwise the user will see a quick
//flash of links they shouldn't see as logged out guests.
// ReactDOM.render(

// <Suspense fallback={<div />}>
//   <Provider store={reduxStore}>

//       <ReactReduxFirebaseProvider {...rrfProps}>
//         <AuthIsLoaded>
//           <BrowserRouter>
//             <App />
//           </BrowserRouter>
//         </AuthIsLoaded>
//       </ReactReduxFirebaseProvider>

//   </Provider>
// </Suspense>,
//   document.getElementById('root')
// );
// ReactDOM.render( 
// <Provider store={reduxStore}>
// <BrowserRouter>
//       <Suspense fallback={<div />}>
//           <ReactReduxFirebaseProvider {...rrfProps}>
//                     <Suspense fallback={<div />}>

//                               <AuthIsLoaded>

//                                     <App />

//                               </AuthIsLoaded>
//                       </Suspense>
//           </ReactReduxFirebaseProvider>
//       </Suspense>
// </BrowserRouter>
// </Provider>
//   ,  document.getElementById('root')
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
