
import React from 'react';
import { Default } from 'react-spinners-css';
//import { ReactComponent as UAIcon} from '../../../icons/LogoRightWhite.svg';



const BlankScreen = () => { 

    return(

        <div className='blankscreen-lazyloading-background'>
            <div className='blankscreen-lazyloading-spinner-container center'>
                <Default className='blankscreen-lazyloading-spinner' size={100} color="#645CFF" />
            </div>
        </div>
        

        
    );
        
};

 

export default BlankScreen